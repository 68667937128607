import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "search", "role", "results" ]

  connect() {
    this.filters = { searched: "", role: "", page: "", orderbyAttribute: "", orderbyDirection: "" }
  }

  searchChange(event) {
    this.filters.searched = this.searchTarget.value
    this.filters.page = 1
    this.change()
  }

  roleChange(event) {
    this.filters.role = this.roleTarget.value
    this.filters.page = 1
    this.change()
  }

  paginateChange(event) {
    event.preventDefault()
    this.filters.page = event.currentTarget.innerHTML
    this.change()
  }

  orderbyChange(event) {
    event.preventDefault()
    this.filters.orderbyAttribute = event.currentTarget.id
    if (this.filters.orderbyDirection == "ASC") {
      this.filters.orderbyDirection = "DESC"
    } else {
      this.filters.orderbyDirection = "ASC"
    }
    this.change()
  }

  change() {
    fetch(this.data.get("url"), {
      method: 'POST',
      body: JSON.stringify( this.filters ),
      credentials: "include",
      dataType: 'script',
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"),
        "Content-Type": "application/json"
      },
    })
    .then(response => response.text())
    .then(html => {
      //this.usersTarget.innerHTML = html
      console.log(html)
      document.getElementById("rha").innerHTML = html
      console.log(fetched)
      //this.resultsTarget.innerHTML = html
    })
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}
