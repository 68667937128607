import { Controller } from "stimulus";
import { Toast } from "bootstrap";
export default class extends Controller {
  static targets = ["toast"];

  connect() {
    this.toastsList = this.toastTargets.map((toast) => {
      const newToast = new Toast(toast);
      newToast.show();
      return newToast;
    });
  }
}
