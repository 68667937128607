/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'stylesheets/backoffice';
import 'images/logo_rvb.svg';
import * as Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import debounced from "debounced";
// import MediumEditor from 'medium-editor';
import '@fortawesome/fontawesome-free/js/all.js';
import "@stimulus/polyfills";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import 'selectize/dist/css/selectize.bootstrap3.css';
import 'selectize/dist/js/standalone/selectize.js';
import "blissfuljs/bliss.shy.js";

Rails.start();
Turbolinks.start();
debounced.initialize({ input: { wait: 500 } });
// Look for controllers inside app/javascripts/packs/controllers/
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
window.jQuery = jQuery
window.$ = jQuery
// window.MediumEditor = MediumEditor

document.addEventListener("turbolinks:load", function() {
  $('.with-selectize').each(function(i, el){
    var $el = $(el);
    $el.selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      create: false,
      maxOptions: 50,
      load: $el.data("url") ? function(query, callback) {
        if (!query.length) return callback();

        $.ajax({
          url: $el.data("url") + '?q=' + encodeURIComponent(query),
          type: 'GET',
          error: callback,
          success: callback
        });
      } : null
    });
  });
});
