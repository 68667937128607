import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _kebabCase from "lodash/kebabCase";
import TomSelect from "tom-select/dist/esm/tom-select.js";

TomSelect.define("clear_button", require("tom-select/dist/js/plugins/clear_button.js"));
TomSelect.define("input_autogrow", require("tom-select/dist/js/plugins/input_autogrow.js"));
TomSelect.define("no_active_items", require("tom-select/dist/js/plugins/no_active_items.js"));
TomSelect.define("remove_button", require("tom-select/dist/js/plugins/remove_button.js"));

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  // LIFECYCLE

  initialize() {
    this.settings = {};
    this.markupSettings = {
      create: "boolean",
      maxItems: "integer",
    };
  }

  connect() {
    this._initSettings();

    this.tagSelector = new TomSelect(this.element, this.settings);

    this._initPlaceholder(_isEmpty(_get(this.element, ["options"], [])));
  }

  // ACTIONS

  resetOptions(options) {
    if (!this.tagSelector || !options) {
      return;
    }

    this.tagSelector.clear(true);
    this.tagSelector.clearOptions();
    this.tagSelector.addOptions(options);
    this._initPlaceholder(_isEmpty(options));
  }

  updatePlaceholder(placeholder) {
    if (!this.tagSelector || !placeholder) {
      return;
    }

    this.tagSelector.settings.placeholder = placeholder;
    this.tagSelector.inputState();
  }

  // GETTERS & SETTERS

  get isMultiple() {
    return this.element.multiple;
  }

  // PRIVATE

  _initPlaceholder(emptyOptions) {
    const defaultPlaceholder = this.data.get("default-placeholder");
    const emptyPlaceholder = this.data.get("empty-placeholder");

    if (emptyOptions && emptyPlaceholder) {
      this.updatePlaceholder(emptyPlaceholder);
    } else if (defaultPlaceholder) {
      this.updatePlaceholder(defaultPlaceholder);
    }
  }

  _initSettings() {
    // set available setting from markup
    Object.keys(this.markupSettings).forEach((settingName) => {
      const settingType = this.markupSettings[settingName];
      const settingKebabName = _kebabCase(settingName);
      if (!this.data.has(settingKebabName)) {
        return;
      }

      this.settings[settingName] = this[`_${settingType}Setting`](this.data.get(settingKebabName));
    });

    // set default settings
    this.settings["render"] = {
      no_results: function (_data, _escape) {
        return '<div class="no-results">Aucun résultat trouvé</div>';
      },
    };
    this.settings["plugins"] = { input_autogrow: {}, no_active_items: {} };

    // set settings according to select attributes
    if (this.isMultiple) {
      this.settings["plugins"]["remove_button"] = { title: "Retirer" };
    } else {
      this.settings["plugins"]["clear_button"] = {
        title: "Retirer",
        className: "btn-close btn-sm",
        html: (data) => {
          return `<button type="button" class="${data.className}" title="${data.title}"></button>`;
        },
      };
    }
    if (this.settings["create"]) {
      this.settings["render"]["option_create"] = (data, escape) => {
        return '<div class="create">Ajouter <strong>' + escape(data.input) + "</strong>&hellip;</div>";
      };
    }
  }

  _booleanSetting(setting) {
    return setting == "1" || setting == "true";
  }

  _integerSetting(setting) {
    return parseInt(setting, 10);
  }
}
