import Rails from "@rails/ujs";
import _get from "lodash/get";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["commitInput", "editor", "mediaFormBody", "stateActions", "versionActions"];

  connect() {
    this.mediaController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("body"),
      "media"
    );
    // this.mediaController.toggleLoading(true);
  }

  submit(event) {
    if (this.hasMediaFormBodyTarget) {
      this.mediaController.toggleLoading(false);
    }

    if (!this.hasEditorTarget) {
      return;
    }

    // stop the event propagation
    this.stopEventPropagation(event);

    const submitButton = Rails.getData(event.target, "ujs:submit-button");
    const submitValue = _get(submitButton, "value", "default");

    // don't submit if not from a submit button
    if (submitValue == "default") {
      return;
    }

    // set the commit value according to the button clicked to submit
    if (this.hasCommitInputTarget) {
      this.commitInputTarget.value = submitValue;
    }

    const editorController = this.application.getControllerForElementAndIdentifier(this.editorTarget, "editor");
    if (editorController) {
      // makes sure editor isn't in readOnly
      editorController.editor.readOnly.toggle(false);

      editorController
        .save()
        .then((_data) => {
          setTimeout(() => {
            // N.B.: as of Chrome 53, untrusted events (not triggered by the user) don't invoke the default action
            // https://developer.mozilla.org/en-US/docs/Web/API/Event/isTrusted
            // Rails.fire(event.target, "submit", { propagate: true });
            event.target.submit();
          });
        })
        .catch((error) => {
          if (error.data) {
            const errorData = _get(error, "data", []);
            this.feedback.editorAlert(errorData);
          }

          console.error("Editor's saving failed: ", error);
        });
    }
  }

  showVersionActions() {
    this.toggleVersionActions(true);
  }

  hideVersionActions() {
    this.toggleVersionActions(false);
  }

  toggleVersionActions(toggle) {
    toggle = toggle || false;

    if (this.hasStateActionsTarget) {
      this.stateActionsTarget.hidden = toggle;
    }

    if (this.hasVersionActionsTarget) {
      this.versionActionsTarget.hidden = !toggle;
    }
  }
}
