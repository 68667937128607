import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "search", "user", "maps" ]

  connect() {
    this.filters = { searched: "", user: "", page: "" }
  }

  searchChange(event) {
    this.filters.searched = this.searchTarget.value
    this.change()
  }

  userChange(event) {
    if(this.userTarget.classList.contains("active")) {
      this.filters.user = ""
    } else {
      this.filters.user = this.data.get("user")
    }
    this.userTarget.classList.toggle("active")
    this.change()
  }

  paginateChange(event) {
    event.preventDefault()
    this.filters.page = event.currentTarget.innerHTML
    this.change()
  }


  change() {
    fetch(this.data.get("url"), {
      method: 'POST',
      body: JSON.stringify( this.filters ),
      credentials: "include",
      dataType: 'script',
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"),
        "Content-Type": "application/json"
      },
    })
    .then(response => response.text())
    .then(html => {
      this.mapsTarget.innerHTML = html
    })
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}
