/* global Bliss */
import { createPopper } from "@popperjs/core";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    confirm: String,
    cancel: String,
    dialog: String,
  };

  // LIFECYLE

  initialize() {
    this.dataKey = "confirm";
    this.confirmEvent = "confirmed";
  }

  // ACTIONS

  async toEvent(event) {
    const target = event.currentTarget;

    if (this._shouldConfirm(event) && (await this._confirm())) {
      this.setData(target, this.dataKey, true);
      target.dispatchEvent(new Event(this.confirmEvent));
    }
  }

  async onClick(event) {
    const target = event.currentTarget;

    if (this._shouldConfirm(event) && (await this._confirm())) {
      this.setData(target, this.dataKey, true);
      target.dispatchEvent(new Event("click", { bubbles: true }));
    }
  }

  // PRIVATE

  _shouldConfirm(event) {
    const target = event.currentTarget;

    // no target to dispatch the event to or this event has already been confirmed
    if (!target || this.getData(target, this.dataKey, false)) {
      this.deleteData(target, this.dataKey);
      return false;
    }

    // stop event propagation to confirm
    this.stopEventPropagation(event);

    // dialog already there, action has been re-triggered, don't generate dialog again
    if (this.dialog) {
      return false;
    }

    // create the dialog
    this._createDialog(target);

    // should confim
    return true;
  }

  _confirm() {
    return new Promise((resolve, reject) => {
      if (!this.dialog) {
        reject();
      }

      this.dialog.show();

      const buttons = this.dialog.querySelectorAll("button");

      Bliss.bind([...buttons], {
        click: (event) => {
          resolve(this.getData(event.target, this.dataKey, false));
          this.dialog.parentNode.removeChild(this.dialog);
          delete this.dialog;
        },
      });
    });
  }

  _createDialog(target) {
    if (!target) {
      return;
    }

    const confimButton = Bliss.create({
      tag: "button",
      type: "button",
      className: "btn btn-bo-danger btn-sm",
      textContent: this.confirmValue || "Confirmer",
    });
    this.setData(confimButton, this.dataKey, true);

    const cancelButton = Bliss.create({
      tag: "button",
      type: "button",
      className: "btn btn-bo-link btn-sm ml-1",
      textContent: this.cancelValue || "Annuler",
    });
    this.setData(cancelButton, this.dataKey, false);

    const buttonsStack = Bliss.create({
      tag: "div",
      className: "hstack",
    });

    buttonsStack.appendChild(confimButton);
    buttonsStack.appendChild(cancelButton);

    this.dialog = Bliss.create({
      tag: "dialog",
      className: "dialog-bo",
      contents: [
        {
          tag: "p",
          innerHTML: this.dialogValue || "Confirmez-vous cette action&nbsp;?",
        },
      ],
    });

    this.dialog.appendChild(buttonsStack);

    Bliss.after(this.dialog, target);

    createPopper(target, this.dialog, {
      placement: "auto",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }
}
