import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "userField"
  ];

  toggleUserField() {
    console.log("changeUser"),
    this.userFieldTarget.classList.toggle("d-none");
  }
}